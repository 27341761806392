<mat-drawer-container  class="conteiner-desktop">
    <mat-drawer disableClose #drawer [ngClass]="{'sidenav-desktop': !isPhone}" [mode]="isPhone? 'push': 'side'" [opened]="!isPhone">
        <div class="navigation-list" [ngClass]="{'navigation-list-desktop': !isPhone}">

            <mat-nav-list  class="nav" role="navigation">

                <mat-list-item *ngIf="!isPhone" class="new-logo-1">
                    <img
                    (click)="goToHomePage()"
                    src="../../../../assets/images/logo/logo-conor-admin.png"
                    alt="Agilweb logo image"
                    class="logo new-logo-2"
                    style="width: 230px !important;">
                </mat-list-item>

                <mat-list-item class="mt-2 new-scale-item-menu" routerLink="/profile" [routerLinkActive]="['is-active']"(click)="handleRedirectTo('profile')" style="margin-top: 30px !important;">
                    <mat-icon mat-list-icon>account_circle</mat-icon>
                    <span mat-line style="font-size: 15px !important; margin-left: -7px;">Meu Perfil</span>
                </mat-list-item>

                <mat-list-item
                userRole permission="{{accessControl.ROLE_PANEL_READ}}"
                class="second-item new-scale-item-menu"
                routerLink="/dashboard"
                [routerLinkActive]="['is-active']">

                    <span mat-list-icon  class="material-icons">dashboard</span>
                    <span mat-line style="font-size: 15px !important; margin-left: -7px;">Painel</span>
                </mat-list-item>

                <mat-list-item
                userRole permission="{{accessControl.ROLE_CUSTOMERS_READ}}"
                routerLink="/customers"
                [routerLinkActive]="['is-active']"
                class="new-scale-item-menu">

                    <mat-icon mat-list-icon>person</mat-icon>
                    <span mat-line style="font-size: 15px !important; margin-left: -7px;">Clientes</span>
                </mat-list-item>

                <mat-accordion
                    userRole permission="{{accessControl.ROLE_FINANCIAL_MENU_READ}}">
                    <mat-expansion-panel hideToggle [expanded]="true">
                    <mat-expansion-panel-header
                        [ngClass]="{'is-active': chargesActive.isActive ||
                        statementActive.isActive ||
                        dunningsActive.isActive || productsActive.isActive}">

                        <mat-panel-title>
                            <span mat-list-icon  class="material-icons">attach_money</span>
                            <span mat-line style="font-size: 15px !important; margin-left: -14px;">Financeiro</span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                        <mat-list-item
                        userRole permission="{{accessControl.ROLE_CHARGES_READ}}"
                        routerLink="/charges"
                        class="new-scale-item-menu"
                        [routerLinkActive]="['sub-menu-active']" #chargesActive="routerLinkActive">
                            <span mat-list-icon  class="material-icons">price_check</span>
                            <span mat-line style="font-size: 15px !important; margin-left: -7px;">Cobranças</span>
                        </mat-list-item>

                        <mat-list-item
                            userRole permission="{{accessControl.ROLE_STATEMENT_READ}}" class="new-scale-item-menu"
                            #statementActive="routerLinkActive" routerLink="/statement" [routerLinkActive]="['sub-menu-active']"
                        >
                            <span mat-list-icon  class="material-icons">
                                currency_exchange
                                </span>
                            <span mat-line style="font-size: 15px !important; margin-left: -7px;">Extrato</span>
                        </mat-list-item>

                        <mat-list-item
                        accountBreakpointVersion menuCheck=true
                        userRole permission="{{accessControl.ROLE_STATEMENT_READ}}"
                        #productsActive="routerLinkActive" 
                        routerLink="/products" 
                        class="new-scale-item-menu"
                        [routerLinkActive]="['sub-menu-active']"
                        >
                            <span mat-list-icon  class="material-icons"> shopping_bag </span>
                            <span mat-line style="font-size: 15px !important; margin-left: -7px;">Produtos</span>
                        </mat-list-item>

                        <mat-list-item
                        userRole permission="{{accessControl.ROLE_DUNNINGS_READ}}"
                        #dunningsActive="routerLinkActive"
                        routerLink="/dunnings" class="new-scale-item-menu"
                        [routerLinkActive]="['sub-menu-active']"
                        >
                            <span mat-list-icon class="material-icons-round"> do_not_disturb_on </span>
                            <span mat-line style="font-size: 15px !important; margin-left: -7px;">Negativações</span>
                        </mat-list-item>
                    </mat-expansion-panel>

                </mat-accordion>

                <mat-accordion
                    userRole permission="{{accessControl.ROLE_DEVICES_READ}}">
                    <mat-expansion-panel hideToggle [expanded]="true">
                    <mat-expansion-panel-header
                        [ngClass]="{'is-active': devicesActive.isActive ||
                        vehiclesActive.isActive ||
                        OSActive.isActive ||
                        technicians.isActive || reports.isActive }">
                        <mat-panel-title>
                            <span mat-list-icon  class="material-icons">engineering</span>
                            <span mat-line style="font-size: 15px !important; margin-left: -7px;">Operacional</span>
                            <!-- <span class="new" mat-line style="margin-left: 10px !important;">NOVO</span> -->
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                        <mat-list-item
                        userRole permission="{{accessControl.ROLE_CHARGES_READ}}" class="new-scale-item-menu"
                        routerLink="/devices"  #devicesActive="routerLinkActive"
                        [routerLinkActive]="['sub-menu-active']"
                        >
                            <span mat-list-icon  class="material-icons">satellite_alt</span>
                            <span mat-line style="font-size: 15px !important; margin-left: -7px;">Dispositivos</span>
                        </mat-list-item>

                        <mat-list-item
                        userRole permission="{{accessControl.ROLE_VEHICLES_READ}}" class="new-scale-item-menu"
                        routerLink="/vehicles" #vehiclesActive="routerLinkActive"  [routerLinkActive]="['sub-menu-active']">
                            <span mat-list-icon  class="material-icons">directions_car</span>
                            <span mat-line style="font-size: 15px !important; margin-left: -7px;">Veículos</span>
                        </mat-list-item>

                        <mat-list-item
                        userRole permission="{{accessControl.ROLE_SERVICE_ORDERS_READ}}"
                        routerLink="/ordem-servico" class="new-scale-item-menu"
                        #OSActive="routerLinkActive"
                        [routerLinkActive]="['sub-menu-active']"
                        >
                            <span mat-list-icon  class="material-icons">description</span>
                            <span mat-line style="font-size: 15px !important; margin-left: -7px;">Ordem de Serviço</span>
                        </mat-list-item>

                        <mat-list-item
                        userRole permission="{{accessControl.ROLE_TECHNICIANS_READ}}"
                        routerLink="/tecnicos"
                        #technicians="routerLinkActive" class="new-scale-item-menu"
                        [routerLinkActive]="['sub-menu-active']">
                            <span mat-list-icon  class="material-icons">manage_accounts</span>
                            <span mat-line style="font-size: 15px !important; margin-left: -7px;"> Técnicos </span>
                        </mat-list-item>


                        <mat-list-item
                        routerLink="/relatorios"
                        #reports="routerLinkActive" class="new-scale-item-menu"
                        [routerLinkActive]="['sub-menu-active']">
                            <span mat-list-icon  class="material-icons"> bar_chart </span>
                            <span mat-line style="font-size: 15px !important; margin-left: -7px;"> Relatórios </span>
                        </mat-list-item>

                    </mat-expansion-panel>

                </mat-accordion>




<!--                <mat-list-item routerLink="/charges" [routerLinkActive]="['is-active']">-->
<!--                    <span mat-list-icon  class="material-icons">attach_money</span>-->
<!--                    <span mat-line>Cobranças</span>-->
<!--                </mat-list-item>-->

                <!-- <mat-list-item routerLink="/invoice" [routerLinkActive]="['is-active']">
                    <mat-icon mat-list-icon class="material-icons-round"> description </mat-icon>
                    <span mat-line> Notas Fiscais </span>
                </mat-list-item> -->


                <!--
                <mat-list-item routerLink="/admin" [routerLinkActive]="['is-active']">
                    <mat-icon mat-list-icon class="material-icons-round">
                            admin_panel_settings
                    </mat-icon>
                    <span mat-line> Contas </span>
                </mat-list-item>
                -->

                <mat-list-item (click)="handleLogout()" class="last-item new-last-item" >
                    <mat-icon mat-list-icon class="material-icons-round">logout</mat-icon>
                    <span mat-line style="font-size: 15px !important; margin-left: -7px;">Sair</span>
                </mat-list-item>

<!--                <mat-list-item outerLink="/dashboard" [routerLinkActive]="['is-active']" >-->
<!--                    <mat-icon mat-list-icon>description</mat-icon>-->
<!--                    <span mat-line>Relatórios</span>-->
<!--                </mat-list-item>-->

            </mat-nav-list>

        </div>



    </mat-drawer>

    <mat-drawer-content [ngClass]="{'desktop-content': !isPhone,   'phone-content' : isPhone}" >
        <ng-content></ng-content>

    </mat-drawer-content>
</mat-drawer-container>
