<mat-toolbar class="header-container" >

    <div class=mat-icon-button (change)="handleAppBarMenuButton()" class="menu-container">
        <input class="checkbox" type="checkbox" id="checkbox-menu">
        <label class="label" for="checkbox-menu"> 
            <span class="underline"></span>
            <span class="underline"></span>
            <span class="underline"></span>
        </label>
    </div>

    <img 
        (click)="goToHomePage()"
        src="../../../../assets/images/logo/logo-conor-admin.png"
        alt="Agilweb logo image" 
        class="logo"
        style="width: 100px !important;"
    >

    <div class="btn">
        <button  id="icon-notify">
            <mat-icon>notifications</mat-icon>
        </button>
        <button (click)="handleShowManual()" title="Acessar manual Agilweb" mat-fab color="primary" class="agil-web-fab">
            <mat-icon id="icon-question">question_mark</mat-icon>
        </button>
    </div>
</mat-toolbar>



