import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ResumeParams, ResumeRequest } from '../../features/charges/charges.component';
import { DateFilterModel } from '../../features/helpers/filter-bottom-sheet/filter';
import { InvoiceModel } from '../../features/invoice/invoice';

import { ReturnModalComponent } from '../return-modal/return-modal.component';
import { ActivateDunning, BalanceRequest, BankModel, ChargeParams, DefaultTaxes, IExtrato, invoiceRequest, StatementRequest } from './finance';
import {PrintSubsBodyRequest} from "../../features/customer/detail-customer/financeiro/financeiro.component";

@Injectable({
  providedIn: 'root'
})
export class FinanceService {

  private statementData$: Observable<any> | null = null
  private balanceData$: Observable<BalanceRequest> | null = null
  private invoiceData$: Observable<any> | null = null
  private dunningsData$: Observable<any> | null = null
  private semesterValue$: Observable<any> | null = null



  constructor(
    private httpClient: HttpClient,
  ) { }

  public statementEventEmitter: EventEmitter<any> =
  new EventEmitter()

  public balanceEventEmitter: EventEmitter<any> =
  new EventEmitter()

  public invoiceEventEmitter: EventEmitter<any> =
  new EventEmitter()

  public dunningsEventEmitter: EventEmitter<any> =
  new EventEmitter()

  public semesterValuesEventEmitter: EventEmitter<any> =
  new EventEmitter()







  public getStatement(filterDate: DateFilterModel): any {


      // check not defined

      const params = new HttpParams()
      .set('start', filterDate.start ? filterDate.start : '')
      .set('end', filterDate.end ? filterDate.end : '')
      .set('page', filterDate.page)
      .set('page', filterDate.page);

      this.statementData$ = this.httpClient.get<StatementRequest>(environment.apiUrl + '/financeiro/extrato', {params})



    this.statementData$.subscribe((data) => {
      this.statementEventEmitter.emit(data)
    })


  }

  public exportExtratos(filterDate: DateFilterModel | null): Observable<IExtrato[]> {
    const params = new HttpParams()
    .set('startDate', filterDate?.start ? filterDate.start : '')
    .set('finishDate', filterDate?.end ? filterDate.end : '')
    
    return this.statementData$ = this.httpClient
    .get<IExtrato[]>(environment.apiUrl + '/financeiro/export', {params})
  }

  public getPendingValue(): Observable<any> {
    return  this.httpClient.get<StatementRequest>(environment.apiUrl + '/financeiro/pendente')

  }

  getBankList(): any {
    return this.httpClient.get<any>(environment.apiUrl + '/financeiro/bancos/lista')

  }

  public getConfirmedValue(): Observable<any> {
     return  this.httpClient.get<StatementRequest>(environment.apiUrl + '/financeiro/repasse')
  }

  saveNewBank(Bank: BankModel) {

    return this.httpClient.post<any>(
      environment.apiUrl + '/admin/banco',
      Bank
    )

  }

  public getBankAccount(): Observable<any> {
    return  this.httpClient.get<StatementRequest>(environment.apiUrl + '/financeiro/banco')
  }

  public getCustomerBankAccount(): Observable<any> {
    return  this.httpClient.get<StatementRequest>(environment.apiUrl + '/financeiro/conta')
  }

  public getBalance(): void {

    this.balanceData$ = this.httpClient.get<BalanceRequest>( environment.apiUrl + '/financeiro/saldo')

    this.balanceData$.subscribe((data) => {
      this.balanceEventEmitter.emit(data)
    })
  }

  public getInvoices(objParams: any): void {

    if(objParams) {

      const params = new HttpParams()
      .set('page', objParams.page)
      .set('start', objParams.dateRange.start)
      .set('end', objParams.dateRange.end)
      .set('page_size', objParams.page_size)
      .set('term', objParams.searchTerm)
      .set('tags', objParams.tags?.join(","));

      this.invoiceData$ = this.httpClient.get<invoiceRequest>(environment.apiUrl + '/nota-fiscal', {params})

    }else{

      const params = {
        page: 1,
        page_size: 10
      }

      this.invoiceData$ = this.httpClient.get<invoiceRequest>(environment.apiUrl + '/nota-fiscal', {params})
    }



    this.invoiceData$.subscribe((data) => {
      this.invoiceEventEmitter.emit(data)
    })
  }

  public newInvoice(body: InvoiceModel): any {

     return this.httpClient.post<any>(environment.apiUrl + '/nota-fiscal', body)

  }

  public withDraw(valor:number): any {

    return this.httpClient.post<any>(environment.apiUrl + '/financeiro/saque', {"valor": valor})

  }

  public getDunningList(reqParams?: any): void {

    if(reqParams) {

      const params = new HttpParams()
      .set('page', reqParams.page)
      .set('start', reqParams.dateRange.start)
      .set('end', reqParams.dateRange.end)
      .set('page_size', reqParams.page_size)
      .set('term', reqParams.searchTerm)

      this.dunningsData$ = this.httpClient.get<any>(environment.apiUrl + '/negativacao', {params})


    }else{

      const params = {
        page: 1,
        page_size: 10
      }

      this.dunningsData$ = this.httpClient.get<any>(environment.apiUrl + '/negativacao', {params})

    }

    this.dunningsData$.subscribe((data) => {
      this.dunningsEventEmitter.emit(data)
    })
  }

  public getCustomerDunnings(id: string): Observable<any> {
    return this.httpClient.get<any>(environment.apiUrl + '/negativacao/lista/'+ id)
  }

  public getCustomerInvoices(id: string): Observable<any> {
    return this.httpClient.get<any>(environment.apiUrl + '/nota-fiscal/lista/'+ id)
  }

  public cancelInvoice(id: string): Observable<any> {
    return this.httpClient.delete<any>(environment.apiUrl + '/nota-fiscal/'+ id)
  }

  public chartSemesterValue(): void {

    this.semesterValue$ = this.httpClient.get<invoiceRequest>(environment.apiUrl + '/financeiro/semestre')

    this.semesterValue$.subscribe((res) => {
      this.semesterValuesEventEmitter.emit(res)
    })

  }

  public activateDunnig(dunningBodyRequest: ActivateDunning): Observable<any> {

    return this.semesterValue$ = this.httpClient.post<any>(environment.apiUrl + '/negativacao', dunningBodyRequest)

  }


  public removeDunning(id:number): Observable<any>{

    return this.httpClient.delete<any>(environment.apiUrl + '/negativacao/' + id)

  }



  public confirmCashPayment(id:number, chargeInfo:any):Observable<any> {

    return this.httpClient.post<any>(
    environment.apiUrl + '/financeiro/pagamento/'+id, chargeInfo)

  }

  public cancelCashPayment(id:number, chargeInfo:any):Observable<any> {

    return this.httpClient.post<any>(
    environment.apiUrl + '/financeiro/estorno/'+id, chargeInfo)

  }

  getDefaultTaxes(): Observable<DefaultTaxes>  {

    return this.httpClient.get<any>(environment.apiUrl + '/financeiro/taxes')

  }

  public getCharges(objParams?:ChargeParams): Observable<any> {
    let params

    if(objParams) {

      params = new HttpParams()
      .set('page', objParams.page)
      .set('page_size', objParams.page_size)
      .set('tags', objParams.filter?.join(","))
      .set('start', objParams.date.start)
      .set('end', objParams?.date.end)
      .set('sort', objParams?.sort  )

      if(objParams.status){
        params = params.set('status', objParams.status.toString())
      }

    }else {
      params = {
        page: 1,
        page_size: 10
      }
    }

    return this.httpClient.get(environment.apiUrl + '/cobrancas', {params})

  }


  public exportChargeList(objParams:ChargeParams): Observable<any> {
    let params

    params = new HttpParams()
    .set('page', objParams.page)
    .set('page_size', objParams.page_size)
    .set('start', objParams.date.start)
    .set('end', objParams.date.end)
    .set('tags', objParams.filter?.join(","));

    return this.httpClient.get<any>(environment.apiUrl + '/cobrancas/export', {params})
  }

  public getAccountServices() {
    return this.httpClient.get<any>(
      environment.apiUrl + '/nota-fiscal/servicos'
    )
  }

  public getChargesResume(rParams: ResumeParams): Observable<ResumeRequest> {

    let params = new HttpParams()
    .set('tags', rParams.tags?.join(","))
    .set('start', rParams.rangeDate.start)
    .set('end', rParams.rangeDate.end)

    return this.httpClient.get<any>(
      environment.apiUrl + '/financeiro/resumo', {params}
    )
  }

  public printSubscriptionsBook(id:number, body : PrintSubsBodyRequest) {
    const headers = new HttpHeaders({
      'Accept': 'application/pdf'
    });

    const params = new HttpParams()
    .set('order', body.order)
    .set('month', body.month)
    .set('year', body.year)

    return this.httpClient.get(
      environment.apiUrl + '/financeiro/subs/'+id, { headers, params, responseType: 'blob' }
    )
  }

  public get_overdue_limit_days() {
    return this.httpClient.get(
      environment.apiUrl + '/financeiro/overdue-limit'
    )
  }

  public set_overdue_limit_days(data: { newlimit: string}):Observable<any> {

    return this.httpClient.put<any>(
    environment.apiUrl + '/financeiro/overdue-limit', data)

  }



}




