import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditUserComponent } from './users-settings/edit-user/edit-user.component';
import { ProfileComponent } from './profile/profile.component';
import { UserComponent } from './user.component';
import { UserRoutingModule } from './user-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderModule } from 'src/app/shared/header/header.module';
import { MaterialExampleModule } from 'src/material.module';
import { UtilidadesComponent } from './utilidades/utilidades.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { UsersSettingsComponent } from './users-settings/users-settings.component';
import { CreateNewUserComponent } from './users-settings/create-new-user/create-new-user.component';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { BankComponentAdminView } from './bank-admin-view/bank-admin-view';


@NgModule({
  declarations: [ UserComponent, EditUserComponent, CreateNewUserComponent, ProfileComponent, UtilidadesComponent, UsersSettingsComponent, BankComponentAdminView],
  imports: [
    CommonModule,
    UserRoutingModule,
    FormsModule,
    HeaderModule,
    CommonModule,
    MaterialExampleModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    DirectivesModule
  ]
})
export class UserModule { }
