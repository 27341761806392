import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { catchError } from 'rxjs';
import { ErrorBottomSheet } from 'src/app/features/helpers';
import { SucessBottomSheetComponent } from 'src/app/features/helpers/sucess-bottom-sheet/sucess-bottom-sheet.component';
import { BaseComponentComponent } from 'src/app/shared/base-component/base-component.component';
import { BankModel } from 'src/app/shared/finance-service/finance';
import { FinanceService } from 'src/app/shared/finance-service/finance.service';
import { CustomValidations } from 'src/app/shared/utils/cpfCnpj';
import { AccountModel } from '../../admin/account';
import { AdminService } from '../../admin/admin.service';


@Component({
  selector: 'app-bank-admin-view',
  templateUrl: './bank-admin-view.html',
  styleUrls: ['./bank-admin-view.scss']
})


export class BankComponentAdminView extends BaseComponentComponent implements OnInit, OnChanges {


  constructor(
    private _adminService: AdminService,
    private _financeService: FinanceService,
    private _bottomSheet: MatBottomSheet,
  ) {
    super()
  }

  @Input()
  account!: AccountModel

  bankModel!: FormGroup
  isEdit: boolean = false

  bankList:any
  filteredOptions:any

  userBank: any;

  displayFn(bank: any): string {
    return bank ? bank.nome : '';
  }


  createBankObject( bankForm: FormGroup ): any {

    let banco = bankForm.controls['banco'].value

    const newBank: any = {
      titular: bankForm.controls['titular'].value,
      cpf: bankForm.controls['cpf'].value,
      bankCode: banco.codigo ,
      agency: bankForm.controls['agencia'].value,
      agencyDigit: bankForm.controls['agenciaDigito'].value,
      account: bankForm.controls['conta'].value,
      accountDigit: bankForm.controls['contaDigito'].value,
    }

    return newBank
  }

  ngOnInit(): void {

    this.initForm()
    this.getBanklist()
  }

  ngOnChanges(): void{

    if (this.account) {
      this.isEdit = true
      this.getCustomerBank(this.account)
    }

  }

  filterBank(BankCode:any): string {

    this.filteredOptions = this.bankList.filter( (bank: any) => {
      if( bank.codigo === BankCode ){

        return bank.nome
      }
    })

  return ''
  }

  initForm():void {

    this.bankModel = new FormGroup({

      banco: new FormControl('', Validators.required),

      agencia: new FormControl('', Validators.required),

      agenciaDigito: new FormControl(
        '',
        Validators.compose([
          Validators.maxLength(1),
        ])
      ),

      conta: new FormControl('', Validators.required),

      contaDigito: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(1),
        ])
      ),

      titular: new FormControl('', Validators.required),

      cpf: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(11),
          CustomValidations.validateCpfCnpj
        ])
      )
    })


    if(this.userBank) {

      this.filterBank(this.userBank.bankCode)
      this.bankModel.controls['banco'].setValue( this.filteredOptions[0] )
      this.bankModel.controls['agencia'].setValue(this.userBank.agency)
      this.bankModel.controls['agenciaDigito'].setValue(this.userBank.agencyDigit)
      this.bankModel.controls['conta'].setValue(this.userBank.account)
      this.bankModel.controls['contaDigito'].setValue(this.userBank.accountDigit)
      this.bankModel.controls['titular'].setValue(this.userBank.name)
      this.bankModel.controls['cpf'].setValue(this.userBank.cpfCnpj)

    }

    this.bankModel.get('banco')!.valueChanges.subscribe( response => {
      this.filterData(response)
    } )

  }

  filterData(enteredData : string):any {
    this.filteredOptions = this.bankList.filter(
      (data: { codigo: string, nome:string } ) =>  {

        return data.nome
        .toLowerCase()
        .indexOf(enteredData?.toLowerCase()) > - 1
      })
  }

  getCustomerBank(account: AccountModel): any {
    this._adminService.getCustomerBankAccount(account.id).subscribe(
      (response:any) => {
        this.userBank = response
        this.initForm()
      })
  }

  getBanklist():void  {
    this._financeService.getBankList().subscribe(
      (response:any) => {
        this.bankList = response
        this.filteredOptions = response
      }
    )
  }

  handleSavebank():void {

    if(this.bankModel.valid){

      if(!this.userBank) {

        const bankObj = this.createBankObject(this.bankModel)
        bankObj.userId = this.account.id

        this.addNewbank(bankObj)

      }else{

        let bank = this.bankModel.controls['banco'].value



        this.userBank.titular  = this.bankModel.controls['titular'].value
        this.userBank.cpf  = this.bankModel.controls['cpf'].value
        this.userBank.bankCode  = bank.codigo
        this.userBank.agency  = this.bankModel.controls['agencia'].value
        this.userBank.agencyDigit  = this.bankModel.controls['agenciaDigito'].value
        this.userBank.account  = this.bankModel.controls['conta'].value
        this.userBank.accountDigit  = this.bankModel.controls['contaDigito'].value

        this.editBank(this.account.id, this.userBank)

      }

    }else {

      this.bankModel.markAllAsTouched()

    }
  }

  addNewbank(bank: BankModel) {
    this._financeService
      .saveNewBank(bank)
      .pipe(
        catchError((err: any) => {
          throw err
        }),
      )
      .subscribe(
        {
          next: () =>  {
            this._bottomSheet.open(SucessBottomSheetComponent,
              {
                data: 'Banco Cadastrado!',
              })
          },
          error: (errorData) =>  {

            this._bottomSheet.open(ErrorBottomSheet,
              { data:
                {
                  redirectTo: 'admin',
                  error: errorData
                }
              })
          }
        }
      )
  }

  editBank(id:any, bank: BankModel) {
    this._adminService
      .updateCustomerBankAccount(id, bank)
      .pipe(
        catchError((err: any) => {
          throw err
        }),
      )
      .subscribe(
        {
          next: () =>  {
            this._bottomSheet.open(SucessBottomSheetComponent,
              {
                data: 'Alterações Salvas!',
              })
          },
          error: (errorData) =>  {
            const err = { message: errorData.error }
            this._bottomSheet.open(ErrorBottomSheet,
              { data:
                {
                  error: err
                }
              })
          }
        }
      )
  }

}
