import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http'
import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'
import {NgbModule} from '@ng-bootstrap/ng-bootstrap'
import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {HeaderModule} from './shared/header/header.module'
import { HeaderAdminModule } from './shared/header-admin/header-admin.module'
import {LoaderModule} from './shared/loader/loader.module'
import {PipesModule} from './shared/pipes/pipes.module'
import {UserAuthorized} from './core/guards/user-authorized.guard'
import {UserAlreadyLogged} from './core/guards/user-already-logged.guard'
import {DatePipe, registerLocaleData, UpperCasePipe} from '@angular/common'
import {AuthInterceptor} from './core/interceptors/auth.interceptor'
import {ErrorInterceptor} from './core/interceptors/error.interceptor'
import {LoaderInterceptor} from './core/interceptors/loader.interceptor'
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome'

import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {NgChartsModule} from 'ng2-charts'

import {BaseComponentComponent} from './shared/base-component/base-component.component'
import {BreakpointObserver} from "@angular/cdk/layout";
import {SucessBottomSheetComponent} from './features/helpers/sucess-bottom-sheet/sucess-bottom-sheet.component';
import {MaterialExampleModule} from "../material.module";
import localePt from '@angular/common/locales/pt';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgilwebFilterModule } from './shared/agilweb-filter/agilweb-filter.module'
import { FilterBottomSheetComponent } from './features/helpers/filter-bottom-sheet/filter-bottom-sheet.component'
import {MatChipsModule} from '@angular/material/chips';
import { JwtModule } from "@auth0/angular-jwt";
import { AdminAuthorized } from './core/guards/admin-authorized.guard';
import { UserModule } from './features/user/user.module';
import { ClientAreaModule } from './features/client-area/client-area.module';
import { ExternalLinksModule } from './features/external-links/external-links.module';
import { ConfirmationBottomSheetComponent } from './features/helpers/confirmation-bottom-sheet/confirmation-bottom-sheet.component';

export let AppResponsive: BreakpointObserver;

registerLocaleData(localePt, 'pt');
@NgModule({
  declarations: [
    AppComponent, 
    BaseComponentComponent, 
    SucessBottomSheetComponent, 
    FilterBottomSheetComponent, ConfirmationBottomSheetComponent 
  ],

  imports: [
    BrowserModule,  
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LoaderModule,
    HeaderModule,
    HeaderAdminModule,
    NgbModule,
    PipesModule,
    FontAwesomeModule,
    SweetAlert2Module.forRoot(),
    NgChartsModule,
    MaterialExampleModule,
    FormsModule,
    ReactiveFormsModule,
    AgilwebFilterModule,
    MatChipsModule,
    JwtModule,
    UserModule,
    ClientAreaModule,
    ExternalLinksModule,
    JwtModule.forRoot({
      config: {
        tokenGetter:  () => localStorage.getItem('access_token')
      }
    })
  ],

  providers: [
    UserAuthorized,
    AdminAuthorized,
    UserAlreadyLogged,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide:  DEFAULT_CURRENCY_CODE,useValue: 'BRL'      },
    { provide: LOCALE_ID, useValue: 'pt' },
    UpperCasePipe,
    DatePipe,

  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private _reponsive: BreakpointObserver) {
    AppResponsive = this._reponsive;
  }
}
