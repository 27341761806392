import { Quote } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from './user.service';
import { AuthService } from '../auth/auth.service';
import { AdminService } from '../admin/admin.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {


  constructor(
    private route: ActivatedRoute,
    private _userService: UserService,
    private _authService: AuthService,
    private _adminService: AdminService
  ) {}

  user = {}
  isAdmin :boolean = false
  editMode:boolean = false
  account : any

  ngOnInit(): void {
    this.getLoggedUser()
    this.isAdmin = this._authService.isMasterProfile()
  
    let id = localStorage.getItem('accountId');
  
    if (id) {
      let accountId = parseInt(id, 10);
  
      this._adminService.getAccount(accountId).subscribe(data => {
        this.account = data;
      });
    }
  }

  hideEditComponent(reload?:any): void {
    this.editMode = !this.editMode

    if(reload)
    this.getLoggedUser()
  }

  showEditComponent(reload?:any): void {
    this.editMode = true

    if(reload)
    this.getLoggedUser()
  }

  getLoggedUser(): void {

    this._userService.getLoggedUser().subscribe( (data) => {
      this.user = data
    })

  }

}
