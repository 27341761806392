<div class="main" [ngClass]="{'main-phone': isPhone }">
    <div class="card">
        <form [formGroup]="bankModel">

            <div class="medium-input">
                <p class="title">Banco:</p>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input
                    matInput
                    placeholder="Selecione seu banco"
                    formControlName="banco"
                    [matAutocomplete]="auto"
                    >
                    <mat-autocomplete #auto="matAutocomplete"  [displayWith]="displayFn">
                        <mat-option *ngFor="let bank of filteredOptions" [value]="bank" >
                            {{bank.nome}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-error *ngIf="bankModel!.controls['banco']?.errors?.['required'] && bankModel.get('conta')?.touched">
                  Selecionar um banco é obrigatório
                </mat-error>
            </div>

            <div class="flex">
                <div class="medium-input">
                    <p class="title">Agência:</p>
                    <mat-form-field appearance="outline" color="accent">
                        <input matInput formControlName="agencia">
                    </mat-form-field>
                    <mat-error *ngIf="bankModel!.controls['agencia']?.errors?.['required'] && bankModel.get('agencia')?.touched">
                      Informar a agencia é obrigatório
                    </mat-error>
                </div>
                <div class="small-input">
                    <p class="title">Digito:</p>
                    <mat-form-field appearance="outline" color="accent">
                        <input matInput formControlName="agenciaDigito">
                    </mat-form-field>
                </div>
            </div>

            <div class="flex">
                <div class="medium-input">
                    <p class="title">Conta:</p>
                    <mat-form-field appearance="outline" color="accent">
                        <input matInput formControlName="conta">
                    </mat-form-field>
                    <mat-error *ngIf="bankModel!.controls['conta']?.errors?.['required'] && bankModel.get('conta')?.touched">
                      Informar uma conta é obrigatório
                    </mat-error>
                </div>

                <div class="small-input">
                    <p class="title">Digito:</p>
                    <mat-form-field appearance="outline" color="accent">
                        <input matInput  formControlName="contaDigito">
                    </mat-form-field>
                    <mat-error *ngIf="bankModel!.controls['contaDigito']?.errors?.['required'] && bankModel.get('contaDigito')?.touched">
                      Informar o digito é obrigatório
                    </mat-error>
                </div>

            </div>

            <div class="width">
                <p class="title">Nome do titular:</p>
                <mat-form-field appearance="outline" color="accent">
                    <input matInput formControlName="titular">
                </mat-form-field>
                <mat-error *ngIf="bankModel!.controls['titular']?.errors?.['required'] && bankModel.get('titular')?.touched">
                  Informar um nome é obrigatório
                </mat-error>
            </div>

            <div class="width">
                <p class="title">CPF/CNPJ:</p>
                <mat-form-field appearance="outline" color="accent">
                    <input matInput placeholder="CPF/CNPJ" mask="000.000.000-00||00.000.000/0000-00" formControlName="cpf">
                </mat-form-field>
                <mat-error *ngIf="bankModel!.controls['cpf']?.errors?.['invalidDocument'] && bankModel.get('cpf')?.touched">
                    CPF/CNPJ inválido
                </mat-error>
                <mat-error *ngIf="bankModel!.controls['cpf']?.errors?.['required'] && bankModel.get('cpf')?.touched">
                  Informar CPF/CNPJ é obrigatório
                </mat-error>
            </div>

        </form>

        <div class="button-container">
            <button mat-raised-button color="primary" class="new-scale-btn"
                    (click)="handleSavebank()"> Salvar
            </button>
        </div>

    </div>
</div>
