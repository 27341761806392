

<app-header>

    <div class="agilweb-body">

        <mat-tab-group selectedIndex="0" mat-align-tabs="center" [dynamicHeight]='true'>
            <mat-tab label="Cadastro">

                <app-profile
                *ngIf="!editMode"
                [user]="user"
                (showEditComponentEmitter)="showEditComponent($event)"
                ></app-profile>

                <app-edit-user
                *ngIf="editMode"
                [selfEdit]="true"
                [user]="user"
                (hideEditComponentEmitter)="hideEditComponent($event)">
                </app-edit-user>
            </mat-tab>

            <mat-tab label="Banco">
                <app-bank-admin-view  
                    [account]="this.account"
                    >
                </app-bank-admin-view>
            </mat-tab>

            <mat-tab label="Central">
                <app-utilidades
                    [user]="user"
                >
                </app-utilidades>
            </mat-tab>


                <mat-tab *ngIf="this.isAdmin" label="Usuários" >
                    <app-users-settings
                    adminProfileCheck
                    [user]="user">
                    </app-users-settings>
                </mat-tab>



        </mat-tab-group>

    </div>

</app-header>

