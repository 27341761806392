import { cpf, cnpj } from 'cpf-cnpj-validator'
import { AbstractControl } from '@angular/forms'

export function isCpf(cpf: string): boolean {
  return cpf == null ? true : cpf.length < 12 ? true : false
}

export function checkIfDocumentIsValid(document: string): boolean {
  if (document.length === 11) {
    return cpf.isValid(document) ? true : false
  } else {
    return cnpj.isValid(document) ? true : false
  }
}

export class CustomValidations {
  static validateCpfCnpj(controle: AbstractControl) {
    const document = controle.value

    if (document === '') return null

    if (document.length === 11) {
      if (cpf.isValid(document)) return null
    } else {
      if (cnpj.isValid(document)) return null
    }

    return { invalidDocument: true }
  }
}
